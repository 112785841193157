import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { nodeName } from "jquery"
import HeartTick from "../img/hearttick.svg"
import "../styles/spacedetail.scss"
import EnquiryForm from "../components/enquiry"
import SEOHeader from "../components/seo-header"
import SimpleMapMonthly from "../components/simplemapmonthly"
import scrollTo from "gatsby-plugin-smoothscroll"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import moment from "moment"
import NearBy from "../components/nearby"
class Spaces extends React.Component {
  render() {
    var show = "show"

    const post = this.props.data.listings
    //const center = { lat: post.location[0], lng: post.location[1] }
    var activeClass = ""

    const Photos = post.photos.map((photo, index) => (
      <div className="CoworkingimageBox">
        <img
          src={photo.replace(
            "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
            "https://cdn.app.gofloaters.com"
          )}
          className="d-block w-100 img-responsive"
          alt={post.spaceGFName + " " + post.spaceDisplayName}
        ></img>
      </div>
    ))
    const TimeFormat = time => {
      const t = new Date(time)
      const formatted = moment(t).format("MMMM d, YYYY")
      return formatted
    }

    const spaceFeedbackComp = post.spaceFeedback
      ?.sort((a, b) => (a.feedbackDate < b.feedbackDate ? 1 : -1))
      .map(dataDrop => (
        <div className="ratingCard">
          <div className="row">
            <div className="col-md-12">
              <p className="customerName">{dataDrop.customerName}</p>
            </div>
            <div className="col-md-12">
              <p>{dataDrop.commentForSpaces}</p>
            </div>
            <div className="col-md-9 col-6">
              <p>
                <div>
                  <span
                    class={
                      "stars-container stars-" +
                      Number(20) * Number(dataDrop.rating)
                    }
                  >
                    ★★★★★
                  </span>
                </div>
              </p>
            </div>
            <div className="col-md-3 col-6 text-right">
              <p style={{ fontSize: "12px" }}>
                {TimeFormat(dataDrop.feedbackDate)}
              </p>
            </div>
          </div>
        </div>
      ))

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    }

    let SpaceSuitedFor = ""
    if (post.purposesList) {
      SpaceSuitedFor = post.purposesList.map((purpose, index) => (
        <div className="SpaceSuitedFor" key={index}>
          <p>{purpose}</p>
        </div>
      ))
    }

    let operationTime = post.operationTime

    if (post.operationTime !== "") {
      operationTime = post.operationTime
        .split(", ")
        .map((operationTime, index) => (
          <p className="OperationTime" key={index}>
            {operationTime}
          </p>
        ))
    }

    var Title = ""
    if (post.spaceType === "Cafe") {
      Title = post.spaceDisplayName + " - " + post.spaceAddress
    } else {
      Title =
        post.spaceGFName +
        " - " +
        post.OriginalName +
        " - " +
        post.spaceDisplayName +
        " | Coworking Space near " +
        post.spaceAddress +
        ", "
    }
    function tConv24(time24) {
      var ts = time24
      var H = +ts.substr(0, 2)
      var h = H % 12 || 12
      h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
      var ampm = H < 12 ? " AM" : " PM"
      ts = h + ts.substr(2, 3) + ampm
      return ts
    }
    var today = new Date()
    var day = today.getDay()
    var daylist = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]
    var todayDay = daylist[day]

    return (
      <div>
        <Helmet>
          <script
            src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
            integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
            crossorigin="anonymous"
            defer
          ></script>
          <script
            src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
            integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
            crossorigin="anonymous"
            defer
          ></script>
          <script
            src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
            integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
            crossorigin="anonymous"
            defer
          ></script>

          <script type="application/ld+json">{`{"@context":"http://schema.org","@type":"LocalBusiness","@id":"${
            post.slug
          }","name":"${
            post.spaceDisplayName + " - " + post.OriginalName
          }","telephone":"+91 7338 7308 00","url":"https://gofloaters.com/office-space/${
            post.slug
          }/","address":{"@type":"PostalAddress","addressCountry":"IN","streetAddress":"${
            post.spaceAddress
          }","addressLocality":"${
            post.spaceCity
          }","addressRegion":"IN"},"geo":{"@type":"GeoCoordinates","latitude":"${
            post.geoLocation[0]
          }","longitude":"${
            post.geoLocation[1]
          }"},"email":"support@gofloaters.com","image":{"@type":"ImageObject","url":"${
            post.photos[0]
          }"}}`}</script>
        </Helmet>
        <SEOHeader
          title={Title + " - " + post.spaceCity + " | GoFloaters"}
          description={Title + " : " + post.spaceDesc}
          socialURL={post.spaceImage}
          pinterest="true"
        ></SEOHeader>
        <Layout>
          <div className="container spaceDetailContainer">
            <div className="row">
              <div className="col-md-12 addressBar">
                <ol itemscope itemtype="https://schema.org/BreadcrumbList">
                  <li
                    style={{ paddingLeft: "0" }}
                    itemprop="itemListElement"
                    itemscope
                    itemtype="https://schema.org/ListItem"
                  >
                    <Link itemprop="item" to="/office-spaces/">
                      <span itemprop="name">Office Spaces</span>
                    </Link>
                    <meta itemprop="position" content="1" />
                  </li>
                  ›
                  <li
                    itemprop="itemListElement"
                    itemscope
                    itemtype="https://schema.org/ListItem"
                  >
                    <Link
                      to={
                        "/office-spaces/" +
                        post.spaceCity.toLowerCase().replace(" ", "-") +
                        "/all/"
                      }
                      itemscope
                      itemtype="https://schema.org/WebPage"
                      itemprop="item"
                      itemid={
                        "/office-spaces/" +
                        post.spaceCity.toLowerCase().replace(" ", "-") +
                        "/all/"
                      }
                    >
                      <span itemprop="name">{post.spaceCity}</span>
                    </Link>
                    <meta itemprop="position" content="2" />
                  </li>
                  ›
                  <li
                    itemprop="itemListElement"
                    itemscope
                    itemtype="https://schema.org/ListItem"
                  >
                    <span itemprop="name">
                      {post.spaceType === "Cafe"
                        ? post.spaceDisplayName
                        : post.spaceGFName}
                    </span>
                    <meta itemprop="position" content="3" />
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 MobileOnly">
                {post.spaceType === "Cafe" ? (
                  <h1>
                    {post.spaceDisplayName} - {post.OriginalName}
                  </h1>
                ) : (
                  <h1>
                    {post.spaceGFName} - {post.OriginalName}
                  </h1>
                )}
                {post.spaceType !== "Cafe" ? (
                  <p>
                    {post.spaceDisplayName}&nbsp;|&nbsp;
                    <img
                      src="https://assets.gofloaters.com/icons/location.png"
                      width="11px"
                      alt="Space Location"
                    ></img>{" "}
                    {post.spaceAddress}
                    <br></br>
                  </p>
                ) : (
                  <p>
                    <img
                      src="https://assets.gofloaters.com/icons/location.png"
                      width="11px"
                      alt="Space Location"
                    ></img>{" "}
                    {post.spaceAddress}
                    <br></br>
                  </p>
                )}
              </div>
              {post.hasCovidSafeBadge ? (
                <div
                  className="col-5 MobileOnly"
                  style={{ padding: "10px 15px 10px" }}
                >
                  <Link className="verifiedSpaceNew" to="/covid-safety-pledge/">
                    <img
                      src="https://assets.gofloaters.com/hearttick.svg"
                      className="verfiedBadge"
                      title="Verified Spaces"
                      alt="Verified Spaces"
                    ></img>
                    <p>Verified Safe</p>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              <div className="col-md-6">
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  autoPlay={false}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType}
                  dotListClass="custom-dot-list-style"
                >
                  {Photos}
                </Carousel>
                {post.subType.toString() === "Office Space" ? (
                  <div className="SpaceSize">
                    <p>{post.seat} seats available</p>
                  </div>
                ) : (
                  ""
                )}
                <br></br>
                <div className="priceCloumn">
                  <div className="PriceTag">
                    {post.hourPassAvailable && post.priceHr !== " " ? (
                      <p>
                        <b>₹ {post.priceHr}</b> / Hour
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="PriceTag">
                    {post.monthPassAvailable && post.priceMonth !== " " ? (
                      <p>
                        <b>₹ {post.priceMonth}</b> / Month
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="spaceButton">
                  <br></br>
                  {post.online ? (
                    <a data-toggle="modal" data-target="#exampleModal">
                      Enquire Now
                    </a>
                  ) : (
                    <a target="_blank" className="disabled">
                      Currently unavailable
                    </a>
                  )}
                  <br></br>
                  <br></br>
                  {post.dayPassAvailable ? (
                    <div
                      style={{
                        padding: "15px",
                        background: "#f0f0f0",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <h4
                              style={{ textAlign: "left", fontWeight: "bold" }}
                            >
                              Day passes
                            </h4>
                            <p style={{ textAlign: "left", color: "#232323" }}>
                              Plug-and-play office for a day
                            </p>
                          </div>
                          <div className="col-md-6 col-6">
                            <p style={{ textAlign: "right" }}>
                              Available from<br></br>
                              <span
                                style={{ fontSize: "20px", fontWeight: "bold" }}
                              >
                                ₹{post.priceDay}
                              </span>{" "}
                              / day <br></br>
                              <Link
                                to={"/coworking-space/" + post.slug}
                                style={{
                                  background: "none",
                                  color: "#303030",
                                  fontWeight: "bold",
                                  padding: "0px",
                                  borderBottom: "2px solid #303030",
                                  borderRadius: "0",
                                }}
                              >
                                View Details &#62; &#62;
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {post.exclusiveOffer && post.exclusiveOffer !== "NA" ? (
                  <p className="">
                    <img
                      src="https://assets.gofloaters.com/icons/discountoffer.png"
                      width="20"
                      alt="Discount Offer"
                    ></img>{" "}
                    {post.exclusiveOffer}
                    <br></br>
                  </p>
                ) : (
                  ""
                )}
                {post.spaceType === "Cafe" && post.noiseLevel ? (
                  <p className="">
                    <br></br>
                    <img
                      src="https://assets.gofloaters.com/icons/noiselevel.png"
                      width="20"
                    ></img>{" "}
                    Noise Level: {post.noiseLevel}
                    <br></br>
                    <br></br>
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="DesktopOnly">
                      {post.spaceType === "Cafe" ? (
                        <h1>
                          {post.spaceDisplayName} - {post.OriginalName}
                        </h1>
                      ) : (
                        <h1>
                          {post.spaceGFName} - {post.OriginalName}
                        </h1>
                      )}
                      {post.spaceType !== "Cafe" ? (
                        <p>
                          {post.spaceDisplayName}&nbsp;|&nbsp;
                          <img
                            src="https://assets.gofloaters.com/icons/location.png"
                            width="11px"
                            alt="Space Location"
                          ></img>{" "}
                          {post.spaceAddress}
                          <br></br>
                        </p>
                      ) : (
                        <p>
                          <img
                            src="https://assets.gofloaters.com/icons/location.png"
                            width="11px"
                            alt="Space Location"
                          ></img>{" "}
                          {post.spaceAddress}
                          <br></br>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-4 col-6 DesktopOnly"
                  style={{ minHeight: "45px", padding: "5px 0px" }}
                >
                  {post.hasCovidSafeBadge ? (
                    <Link
                      className="verifiedSpaceNew"
                      to="/covid-safety-pledge/"
                    >
                      <img
                        src="https://assets.gofloaters.com/hearttick.svg"
                        className="verfiedBadge"
                        title="Verified Spaces"
                        alt="Verified Spaces"
                      ></img>
                      <p>Verified Safe</p>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 col-6" style={{ padding: "10px 0" }}>
                  {post.Rating ? (
                    <p>
                      <div>
                        <b>Rating:</b> {post.Rating}{" "}
                        <span
                          class={
                            "stars-container stars-" +
                            Number(20) * Number(post.Rating)
                          }
                        >
                          ★★★★★
                        </span>
                        <a
                          style={{ borderBottom: "1px solid #303030" }}
                          onClick={() => scrollTo("#rating")}
                        >
                          {" "}
                          {post.spaceFeedback.length} Rating
                          {post.spaceFeedback.length > 1 ? "s" : ""}
                        </a>
                      </div>
                    </p>
                  ) : (
                    ""
                  )}{" "}
                </div>

                {post.spaceDesc ? (
                  <div>
                    <br></br>
                    <h4>Overview</h4>
                    <p>{post.spaceDesc}</p>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <br></br>

                  <h4>Amenities</h4>
                  {post.Facility.search("Hi Speed WiFi") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/hi_speed_wifi.png"
                        alt="Hi Speed WiFi"
                      ></img>
                      <p>Hi Speed WiFi</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Power Backup") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/power_backup.png"
                        alt="Power Backup"
                      ></img>
                      <p>Power Backup</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("WiFi Backup") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/wifi_backup.png"
                        alt="WiFi Backup"
                      ></img>
                      <p>WiFi Backup</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Projector") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/projector.png"
                        alt="Projector"
                      ></img>
                      <p>Projector</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Television") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/television.png"
                        alt="Television"
                      ></img>
                      <p>Television</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Whiteboard") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/whiteboard.png"
                        alt="Whiteboard"
                      ></img>
                      <p>Whiteboard</p>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="amenities-box">
                  <br></br>

                  <h4>Space Info</h4>
                  {post.Facility.search("AC") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/ac.png"
                        alt="AC"
                      ></img>
                      <p>AC</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Metro Nearby") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/metro_nearby.png"
                        alt="Metro Nearby"
                      ></img>
                      <p>Metro Nearby</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Free Parking") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/free_parking.png"
                        alt="Free Parking"
                      ></img>

                      <p>Free Parking</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Rest Rooms") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/rest_rooms.png"
                        alt="Rest Rooms"
                      ></img>
                      <p>Rest Rooms</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Coffee/Tea") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/coffee_tea.png"
                        alt="Coffee/Tea"
                      ></img>
                      <p>Coffee/Tea</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Pantry") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/pantry.png"
                        alt="Pantry"
                      ></img>
                      <p>Pantry</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Meeting Rooms") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/meeting_rooms.png"
                        alt="Meeting Rooms"
                      ></img>
                      <p>Meeting Rooms</p>
                    </span>
                  ) : (
                    ""
                  )}
                  {post.Facility.search("Outside Food Allowed") > 1 ? (
                    <span className="amenities text-center">
                      <img
                        src="https://assets.gofloaters.com/icons/outside_food_allowed.png"
                        alt="Outside Food Allowed"
                      ></img>
                      <p>Outside Food Allowed</p>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {SpaceSuitedFor !== "" ? (
                  <div>
                    <br></br>
                    <h4>Space Best Suited for</h4>
                    {SpaceSuitedFor}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <br></br>
                  <h4>Opening Hours</h4>
                  {/*<p>{operationTime}</p> */}

                  <table className="table">
                    <tbody>
                      <tr>
                        <td colSpan="3" align="center">
                          Weekdays
                        </td>
                      </tr>
                      {post.monday.holiday ? (
                        <tr
                          className={daylist[day] === "Monday" ? "active" : ""}
                        >
                          <td>
                            <strong>Monday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={daylist[day] === "Monday" ? "active" : ""}
                        >
                          <td>
                            <strong>Monday</strong>
                          </td>
                          <td>{tConv24(post.monday.from)}</td>
                          <td>{tConv24(post.monday.to)}</td>
                        </tr>
                      )}

                      {post.tuesday.holiday ? (
                        <tr
                          className={daylist[day] === "Tuesday" ? "active" : ""}
                        >
                          <td>
                            <strong>Tuesday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={daylist[day] === "Tuesday" ? "active" : ""}
                        >
                          <td>
                            <strong>Tuesday</strong>
                          </td>
                          <td>{tConv24(post.tuesday.from)}</td>
                          <td>{tConv24(post.tuesday.to)}</td>
                        </tr>
                      )}
                      {post.wednesday.holiday ? (
                        <tr
                          className={
                            daylist[day] === "Wednesday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Wednesday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={
                            daylist[day] === "Wednesday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Wednesday</strong>
                          </td>
                          <td>{tConv24(post.wednesday.from)}</td>
                          <td>{tConv24(post.wednesday.to)}</td>
                        </tr>
                      )}

                      {post.thursday.holiday ? (
                        <tr
                          className={
                            daylist[day] === "Thursday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Thursday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={
                            daylist[day] === "Thursday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Thursday</strong>
                          </td>
                          <td>{tConv24(post.thursday.from)}</td>
                          <td>{tConv24(post.thursday.to)}</td>
                        </tr>
                      )}
                      {post.friday.holiday ? (
                        <tr
                          className={daylist[day] === "Friday" ? "active" : ""}
                        >
                          <td>
                            <strong>Friday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={daylist[day] === "Friday" ? "active" : ""}
                        >
                          <td>
                            <strong>Friday</strong>
                          </td>
                          <td>{tConv24(post.friday.from)}</td>
                          <td>{tConv24(post.friday.to)}</td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="3" align="center">
                          Weekends
                        </td>
                      </tr>
                      {post.saturday.holiday ? (
                        <tr
                          className={
                            daylist[day] === "Saturday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Saturday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={
                            daylist[day] === "Saturday" ? "active" : ""
                          }
                        >
                          <td>
                            <strong>Saturday</strong>
                          </td>
                          <td>{tConv24(post.saturday.from)}</td>
                          <td>{tConv24(post.saturday.to)}</td>
                        </tr>
                      )}

                      {post.sunday.holiday ? (
                        <tr
                          className={daylist[day] === "Sunday" ? "active" : ""}
                        >
                          <td>
                            <strong>Sunday</strong>
                          </td>
                          <td colSpan="2" align="center">
                            Holiday
                          </td>
                        </tr>
                      ) : (
                        <tr
                          className={daylist[day] === "Sunday" ? "active" : ""}
                        >
                          <td>
                            <strong>Sunday</strong>
                          </td>
                          <td>{tConv24(post.sunday.from)}</td>
                          <td>{tConv24(post.sunday.to)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {post.subType.toString() === "Office Space" ? (
                  <div>
                    <br></br>
                    <h4>Security Deposit</h4>
                    <p>{post.securitydeposit} (for monthly bookings)</p>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <br></br>
                  <h4>Location</h4>
                  <p>
                    <img
                      src="https://assets.gofloaters.com/icons/landmark.png"
                      width="17"
                      alt="Landmark"
                    ></img>{" "}
                    Landmark: {post.landmark}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {post.geoLocation !== "" ? (
                  <SimpleMapMonthly
                    lat={post.geoLocation[0]}
                    lng={post.geoLocation[1]}
                  ></SimpleMapMonthly>
                ) : (
                  ""
                )}
                <br></br> <br></br>
              </div>
            </div>
            <div className="row" id="rating">
              <div className="col-md-12">
                <br></br>
                <h4>Space Review</h4>
                {post.spaceFeedback.length > 0
                  ? spaceFeedbackComp
                  : "No Review"}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <br></br>
                <h4>Space Overview</h4>
                <p
                  className="spaceOverviewtext"
                  dangerouslySetInnerHTML={{ __html: post.spaceOverview }}
                ></p>
                <br></br> <br></br>
              </div>
            </div>
          </div>
          <br></br>
          {post.geoLocation !== "" ? (
            <NearBy
              lat={post.geoLocation[0]}
              lng={post.geoLocation[1]}
              spacetype="monthlyofficeSpace"
              exclude={post.spaceId}
            ></NearBy>
          ) : (
            ""
          )}
        </Layout>
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <EnquiryForm spaceId={post.spaceId} />
          </div>
        </div>
      </div>
    )
  }
}

export default Spaces

export const pageQuery = graphql`
  query MonthlySpacesSlug($slug: String!) {
    listings(slug: { eq: $slug }) {
      spaceDisplayName
      OriginalName
      spaceCity
      spaceGFName
      spaceId
      spaceImage
      spaceTitle
      spaceType
      subType
      monthPassAvailable
      officeSpaceType
      priceDay
      priceHr
      priceMonth
      photos
      Facility
      operationTime
      hourPassAvailable
      exclusiveOffer
      dayPassAvailable
      purposesList
      spaceAddress
      noiseLevel
      landmark
      spaceDesc
      hasCovidSafeBadge
      securitydeposit
      seat
      slug
      geoLocation
      spaceOverview
      online
      Rating
      priceperdayPreDiscount
      priceperhrPreDiscount
      spaceFeedback {
        customerName
        feedbackDate
        gofloaterrating
        rating
        spaceId
        spaceName
        commentForSpaces
        commentForFloaters
      }
      monday {
        from
        to
        useNow
        holiday
      }
      tuesday {
        from
        to
        useNow
        holiday
      }
      wednesday {
        from
        to
        useNow
        holiday
      }
      thursday {
        from
        to
        useNow
        holiday
      }
      friday {
        from
        to
        useNow
        holiday
      }
      saturday {
        from
        to
        useNow
        holiday
      }
      sunday {
        from
        to
        useNow
        holiday
      }
    }
  }
`

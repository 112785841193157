import React, { Component } from "react"
import GoogleMapReact from "google-map-react"

const AnyReactComponent = ({ text }) => <div className="roundCircle"></div>

const SimpleMapMonthly = props => {
  var defaultProps = {
    center: {
      lat: props.lat,
      lng: props.lng,
    },
    latlng: {
      lat: props.lat,
      lng: props.lng,
    },
    zoom: 15,
  }
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "300px", width: "100%" }}>
      <br></br>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBFzQyh0__KlIMKsmGqMIsnsolOeXTYoUs" }}
        defaultZoom={defaultProps.zoom}
        defaultCenter={defaultProps.center}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          new maps.Circle({
            strokeColor: "#25a9d4",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#25a9d4",
            fillOpacity: 0.3,
            map,
            center: defaultProps.latlng,
            radius: 275,
          })
        }
      />
    </div>
  )
}

export default SimpleMapMonthly
